import React from 'react';
import Gallary from '../components/home/Gallary';
import Offer from '../components/home/Offer';
import Banner from '../components/home/Banner';
import Services from '../components/home/Services';
import Upcoming from '../components/home/Upcoming';
import Facilities from '../components/home/Facilities';
import Bookastay from '../components/home/Submitquries';
import Statisticsnew from '../components/home/Statistics';

import SEO from '../components/seo/index';

import Layout from '../layout/index';
// markup
const IndexPage = () => {
  return (
    <>
      <SEO title='Home' />
      <Layout isSticky={true}>
      <section id='home'>
        <Banner/>
      </section>
      <section id='Services'>
        <Services/>
      </section>
      <section id='Offer'>
        <Offer/>
      </section>
      <section id='Facilities'>
        <Facilities/>
      </section>
      <section id='Gallary'>
        <Gallary/>
      </section>
      <section id='Upcoming'>
        <Upcoming/>
      </section>   
  
      <section>
        <Bookastay/>
      </section>
      <section id='Statisticsnew'>
        <Statisticsnew/>
      </section>      
      </Layout>
    </> 
  );
};

export default IndexPage;
