import React from 'react'

import BannerImg1 from '../../assets/images/banner/1.png';
import BannerImg2 from '../../assets/images/banner/2.png';
import BannerImg3 from '../../assets/images/banner/3.png';


//import { Carousel } from 'react-bootstrap';

import Carousel from 'react-multi-carousel';

import "react-multi-carousel/lib/styles.css";
import FloatIcon from './FloatIcons';

export default function Banner() {
    const responsive = {
        desktop: {
            breakpoint: {
                max: 30000,
                min: 768,
            },
            items: 1,
        },
        mobile: {
            breakpoint: {
              max: 767,
              min: 0,
            },
            items: 1,
          },
    };
    const ButtonGroup = ({ next, previous }) => {
        return (
          <div className="carousel-button-group">
            <div className="navigations">
              <button onClick={() => previous()}>
                {/* <img src={treeLeftIconGreen} alt='treeLeftIconGreen' /> */}
              </button>
              <button onClick={() => next()}>
                {/* <img src={treeRightIconGreen} alt='treeRightIconGreen' /> */}
              </button>
            </div>
          </div>
        );
      };
    return (
            <div className='container-fluid p-0 banner-section'>
               
                        <div className="image-wrap">
                        <Carousel
          arrows={false}
          showDots={false}
          autoPlaySpeed={3000}
          autoplay
          draggable
          infinite
          keyBoardControl
          minimumTouchDrag={80}
          partialVisible
          renderButtonGroupOutside={true}
          swipeable
          responsive={responsive}
                  
        >
                            
                            <div className="banner-height">
                                <img data-src={BannerImg2} className='img-100 lazyload' alt='banner1' />
                                <div class="carousel-caption d-block  position-absolute ">
                            <div class="bg-cover w-50 ">
                                <div class="middle">
                                    <h1 class="big-font">FROM WONDER, <br></br> WISDOM</h1>
                                    <p class="img-text">To create a new generation of hearts and minds through
                                        holistic education based on Heart-based Meditation tools and
                                        usher in a highly evolved human civilization</p>

                                    <div class="btn-outer text-start mt-5">
                                        <a href="#Services" class="btn bg-orange btn-rounded">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                            <div className="banner-height">
                                <img data-src={BannerImg3} className='img-100 lazyload' alt='banner1' />
                                <div class="carousel-caption  d-block position-absolute ">
                            <div class="bg-cover w-50 ">
                                <div class="middle">

                                    <h1 class="big-font">FROM WONDER, <br></br> WISDOM</h1>


                                    <p class="img-text">To create a new generation of hearts and minds through
                                        holistic education based on Heart-based Meditation tools and
                                        usher in a highly evolved human civilization</p>


                                    <div class="btn-outer text-start mt-5">
                                        <a href="" class="btn bg-orange btn-rounded">Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                            </div>
                        </Carousel>
                        <FloatIcon/>
                        </div>
                    </div>
              
    )
}
