import React from 'react';

//import Carousel from 'react-multi-carousel';

import Carousel from 'react-grid-carousel';

import "react-multi-carousel/lib/styles.css";

import sleep from '../../assets/images/gallary/img1.png';

import second from '../../assets/images/gallary/img2.png';

import labevent from '../../assets/images/gallary/img3.png';

import group from '../../assets/images/gallary/img4.png';

import sleepGood from '../../assets/images/gallary/img5.png';

import rightarrow from '../../assets/images/gallary/img6.png'

import childplant from '../../assets/images/gallary/img8.png';

import groupyoga from '../../assets/images/gallary/img9.png';

import groupindoor from '../../assets/images/gallary/img10.png';

import groupoutdoor from '../../assets/images/gallary/img11.png';

import stretch from '../../assets/images/gallary/img12.png';

import yogapractice from '../../assets/images/gallary/img13.png';

import meditate from '../../assets/images/gallary/img14.png';

import seminar from '../../assets/images/gallary/img15.png';

import goals from '../../assets/images/gallary/img16.png';

import training from '../../assets/images/gallary/img17.png';

import construction from '../../assets/images/gallary/img18.png';

import seminar1 from '../../assets/images/gallary/img19.png';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        paritialVisibilityGutter: 60
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
        paritialVisibilityGutter: 50
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        paritialVisibilityGutter: 50
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        paritialVisibilityGutter: 30
    }
};

const imageData = [
    { img: second, info: 'Library and Seeker`s Lounge' },
    { img: group, info: 'Book Store' },
    { img: labevent, info: 'Children`s Centre' },
    { img: childplant, info: 'Children`s plant' },
    { img: sleep, info: 'Dining Area and Kitchen' },
    { img: sleepGood, info: 'Dormitory Facilities for transit accomodation' },
    { img: groupyoga, info: 'Yoga group' },
    { img: groupindoor, info: 'Indoor event' },
    { img: groupoutdoor, info: 'Outdoor yoga' },
    { img: stretch, info: 'Yoga strecch' },
    { img: yogapractice, info: 'Practicing yoga' },
    { img: meditate, info: 'Mediation' },
    { img: seminar, info: 'intl-seminar' },
    { img: goals, info: 'goal' },
    { img: training, info: 'training-session' },
    { img: construction, info: 'construction work' },
    { img: seminar1, info: 'hfn seminar' },
]

export default function Facilitiesmedia() {
    const ButtonGroup = ({ next, previous }) => {
        return (
           
            <div className='carousel-button-group'>
                <div className='navigations'>
                    <button onClick={() => previous()} >
                        <img src={rightarrow} style={{ transform: 'rotate(180deg)' }} alt="right" />
                    </button>
                    <button onClick={() => next()} >
                        <img src={rightarrow} alt="right" />
                    </button>
                </div>
            </div>
        );
    };
    return (
        <div className='facilities-section' id='gallery'>
            <div className="container">
            <div class="col-lg-12 col-md-12">
                  <h3 class=" cent-item py-4"> Gallery </h3>
              </div>
                <div className='carousel-section gallery-wrapper'>
                    <Carousel cols={3} rows={2} gap={0} loop>
                    {/*arrowLeft={<a class="gallertCarouselArrow leftArrow carousel-control-prev" role="button" 
                    </Carousel>tabindex="0" href="#"><span aria-hidden="true" class="carousel-control-prev-icon">
    </span><span class="visually-hidden">Previous</span></a>*/} 
                        {imageData.map((image, index) => {
                            return (
                                <Carousel.Item>
                                    <div key={index} >
                                        <img
                                        className='img-gallery'
                                            draggable={false}
                                            alt="text"
                                            src={image.img}
                                        />
                                    </div>
                                   
                                </Carousel.Item>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        </div>

    );
};

