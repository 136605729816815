import React from 'react';

import { Button } from 'react-bootstrap';

import Education from '../../assets/images/services/2330314.png'
import Yoga from '../../assets/images/services/2773663.png'
import Agriculture from '../../assets/images/services/4137640.png'
import Mechanical from '../../assets/images/services/1839325.png'
import Meditationbutvideo from "../home/mediatationbuttonvideo"
import Meditationbutvideo1 from "../home/mediatationbuttonvideo1"


export default function Services() {
    return (
        <section class="py-4" id="services">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 text-center">
                    <h2 class="py-1 s-head">Our Programs</h2>
                    <p class="py-2  w-50 m-auto s-desc">
                        The vision of our founding fathers has been to create a society that is heart-centred. Our
                        services below are stepping stones towards that vision:

                    </p>
                </div>
            </div>

            <div class="row py-4">
                <div class="col-md-3 text-center bg-blue">
                    <div class="box">
                    <img src={Education} alt='Logo' class='img-fluid-services py-3'/>
                        <h3 class="text-head">Education</h3>
                        <p class="text-desc">
                            Specialized programs for cognitive development, faculty development & leadership training
                        </p>
                    </div>
                </div>
                <div class="col-md-3 text-center bg-orchid">
                    <div class="box">
                    <img src={Yoga} alt='Logo' class='img-fluid-services py-3' />
                        <h3 class="text-head">Yoga & Wellness</h3>
                        <p class="text-desc">
                            We have a meditative approach to Yoga that allows us to explore the physical, mental and
                            spiritual all at once. For more information, please visit <a href="https://heartfulness.org/yoga/" className='anchor1' target="_blank">https://heartfulness.org/yoga/</a>
                        </p>
                    </div>
                </div>
                <div class="col-md-3 text-center bg-orange">
                    <div class="box">
                    <img src={Agriculture} alt='Logo' class='img-fluid-services py-3' />
                        <h3 class="text-head">Agriculture & Nursery</h3>
                        <p class="text-desc">
                            We aspire to create a green tomorrow by bringing in a perfect amalgamation between age old
                            growth wisdom and latest
                            propagation techniques like hydroponics, tissue culture and much more.<span className='white-space'><Meditationbutvideo /></span>
                        </p>
                    </div> 
                </div>
                <div class="col-md-3 text-center bg-red">
                    <div class="box">
                    <img src={Mechanical} alt='Logo' class='img-fluid-services py-3' />
                        <h3 class="text-head">Mechanical & Civil</h3>
                        <p class="text-desc">
                            Sustainable constructions have been the benchmark of our spaces, come learn the latest
                            technologies in eco-friendly
                            construction, reuse, renew, recycle and much more.<span className='white-space'><Meditationbutvideo1 /></span>
                        </p>
                    </div>
                </div>
            </div>  
        </div>
    </section>

    )
}
