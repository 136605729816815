
const environment = "production"; //production
const configuration = {
    staging: {
        contactusUrl: "https://g6iqtcvhyl.execute-api.ap-south-1.amazonaws.com/api/het/contactus",
        gtmId: "GTM-5S3496H",
     },
    production: {
        contactusUrl: "https://lavttc60jj.execute-api.ap-south-1.amazonaws.com/api/het/contactus",
        gtmId: "GTM-WS5TNWW",
    }
}

const config = configuration[environment]

module.exports = config;
