import React from 'react'

let InternshipLink = "https://docs.google.com/forms/d/e/1FAIpQLScyJuzkCI_GijYaaryEm4TiBNea3pyK1Ev72fv9P9qNYp4STw/viewform?usp=sf_link";
let GoalLink = "https://docs.google.com/forms/d/e/1FAIpQLScYOaK7L29wn6Jv1oUW-C0qvsQc6CrXCVZXV5r3h8LJCbtMhQ/viewform?usp=sf_link";
let EmployerLink = "https://docs.google.com/forms/d/e/1FAIpQLSe0wnLvYKhv2Tlz7HIFxhj5HLD_ji4K70DZZsZjSQKtSmBkWA/viewform?usp=sf_link";
let HETCareersLink = "https://docs.google.com/forms/d/e/1FAIpQLSeNug0mnRPrC-CqyUWwJi_vA6GX8nSe1WcmiXBn3y_SUkapkQ/viewform?usp=sf_link";


export default function FloatIcon() {

    return (
        <div class="banner-btn-area">
            <a href={InternshipLink} target="_blank" class="btn bg-orange btn-rounded my-2 ba-float-btn">
                <span class="ba-flot-span">Internship<br /></span> Registration
            </a>
            <a href={GoalLink} target="_blank" class="btn bg-orange btn-rounded my-2 ba-float-btn">
                <span class="ba-flot-span">GOAL <br />(finishing school) <br /></span> Registration
            </a>
            <a href={EmployerLink} target="_blank" class="btn bg-orange btn-rounded my-2 ba-float-btn">
                <span class="ba-flot-span">Employer <br /></span> Registration
            </a>
            <a href={HETCareersLink} target="_blank" class="btn bg-orange btn-rounded my-2 ba-float-btn">
                <span class="ba-flot-span">HET<br /></span> Careers
            </a >
        </div >

    )
}
