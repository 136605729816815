const config = {
  meditationdynamic: [
    {
      src: "https://player.vimeo.com/video/681788903?h=b5ad11ae64",
      title: "Agriculture & Nursery",
      description:
        "With regular Heartfulness Meditation,",
    },
  ],
  meditationdynamic1: [
    {
      src: "https://www.youtube.com/embed/Uo7QvMHi5vo",
      title: "Mechanical & civil",
      description:
        "With regular Heartfulness Meditation,",
    },
  ],
  
};

module.exports = config;