import React from "react";
import { Modal } from "react-bootstrap";
import Agriculture from '../../assets/images/services/4137640.png'

import config from "../../assets/data/videoitem";

function MyVerticallyCenteredModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="video">
          {config.meditationdynamic.map(({ src, title, description }) => (
            <iframe
              key={src}
              src={src}
              title={title}
              description={description}
              alt="Agriculture and Nursery"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              width="100%"
              height="410px"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
            ))}
            </div>
      </Modal.Body>
    </Modal>
  );
}

function Meditationbutvideo() {
  const [modalShow, setModalShow] = React.useState(false);

  return (
    <>
      <a
        className="anchor"
        // onKeyDown={() => setModalShow(true)}
        onClick={() => setModalShow(true)}
      >
        {" "}
       Watch Video
      </a>

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default Meditationbutvideo;
