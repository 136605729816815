import React from 'react';

import { Button } from 'react-bootstrap';

import Education from '../../assets/images/offer/Education.svg'
import Yoga from '../../assets/images/offer/Yoga.svg'
import Health from '../../assets/images/offer/Health.svg'
import Agriculture from '../../assets/images/offer/Agriculture.svg'
import Civil from '../../assets/images/offer/Civil.svg'
import IT from '../../assets/images/offer/IT.svg'
import Mechnical from '../../assets/images/offer/Mechnical.svg'
import Logistics from '../../assets/images/offer/Logistics.svg'


export default function Offer() {
    return (
        <section>
        <div class="container" id="offer">
            <div class="row py-4">
                <div class="col-md-12">
                    <h2 class="py-3 offer">What we Offer</h2>
                </div>
            </div>
            <div class="row gx-0">
                <div class="col-md-6">
                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={Education} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Education</h4>
                            <p class="desc">
                                Heartfulness Education enables teachers and students to live a balanced, purposeful, and
                                a happy life using heart-based
                                practices, so that they are equipped to contribute towards a sustainable school reform
                                and community citizenship.
                            </p>
                        </div>
                    </div>

                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={Health} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Health & Wellness</h4>
                            <p class="desc">
                                Make a real change in your wellbeing by gaining awareness in preventive health-care with
                                a retreat including movement,
                                nutrition, life-style guidance and meditation techniques
                            </p>
                        </div>
                    </div>

                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={Civil} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Civil & Construction</h4>
                            <p class="desc">
                                The latest technologies in green construction for a sustainable tomorrow
                            </p>
                        </div>
                    </div>

                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={Mechnical} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Mechanical & Automotive</h4>
                            <p class="desc">
                                Sustainability in action truly by creating, sustaining, re-using and re-cycling
                                resources
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={Yoga} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Yoga</h4>
                            <p class="desc">
                                We have a meditative approach to Yoga that allows us to explore the physical, mental and
                                spiritual all at once.
                                Heartfulness Yoga brings you this full spectrum of Yoga by creating a seamless flow
                                between asanas and meditation
                            </p>
                        </div>
                    </div>

                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={Agriculture} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Agriculture & Nursery</h4>
                            <p class="desc">
                                With a need to consume naturally grown food and have it healthy, we started becoming
                                organic and in this process we
                                learnt the art of being kind to mother earth
                            </p>
                        </div>
                    </div>

                    <div class="row gx-1">
                        <div class="col-md-3">
                        <img src={IT} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">IT & Digital Technologies</h4>
                            <p class="desc">
                                Equipping the future generations with digital possibilities to collaborate and build the
                                next cool things
                            </p>
                        </div>
                    </div>

                    <div class="row gx-1 ">
                        <div class="col-md-3">
                        <img src={Logistics} alt='Logo' class='img-fluid img-offer pt-2'/>
                        </div>
                        <div class="col-md-7 offer-desc">
                            <h4 class="pt-0 text-bold">Logistics & Supply Chain</h4>
                            <p class="desc">The best practices in supply-chain management</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}
